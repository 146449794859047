import React from "react";
import JoinPettoImage from "../../Assets/JoinPetto.svg";

function JoinPetto() {
  const divStyle = {
    backgroundImage: `url(${JoinPettoImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    width: "100%",
    height: "90vh", // Set a fixed height for the div
  };

  return (
    <div>
      <div style={divStyle}></div>
    </div>
  );
}

export default JoinPetto;
