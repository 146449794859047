import React, { useState } from "react";
import {AiFillCaretDown, AiOutlineCheck, AiOutlinePlus} from 'react-icons/ai'
import url from '../url/url'
import { toast } from "react-toastify";

function Services() {
    const [check,setCheck] = useState(false)
    const [genderDD,setGenderDD] = useState(false)
    const [gender,setGender] = useState("male")
    let [images,setImages] = useState([])

    const [breedDD,setBreedDD] = useState(false)
    const [breed,setBreed] = useState("")

    const [name,setName] = useState('')
    const [age,setAge] = useState('')
    const [weight,setWeight] = useState('')


    const [breeds,setBreeds] = useState([
        {type:"Labrador",value:"labrador"},
        {type:"Bull dog",value:"bulldog"}
    ])
  const [serviceProp, setServiceProp] = useState({
    name: "",
    desc: "",
    img: "",
  });

  const handleGender = async (value)=>{
    setGender(value)
    setGenderDD(false)
  }
  const handleBreed = async (value)=>{
    setBreed(value)
    setBreedDD(false)
  }

  function encodeImageFileAsURL(element) {
    console.log("file uploaded: ", element.target.files[0])
    var file = element.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function() {
    //   console.log('RESULT', reader.result)
      setImages([...images,reader.result])
    }
    reader.readAsDataURL(file);
  }

  const handleSubmit = async ()=>{
    if(name==""||weight==""||breed==""||gender==""||age==""){
        return toast.error("kindly fill the required feild")
    }
    const body = {
        Name:name,
        Weight:weight,
        Breed:breed,
        Sex:gender,
        Age:age,
        // Images:images
    }

    try {
        const resp = await url.post('/pets/add',body)
        console.log("petResp :" ,resp.data.data)
        setImages([])
        setGender("male")
        setBreed("")
        setName("")
        setAge("")
        setWeight("")
        return toast.success("successfully added");
    } catch (error) {
      return toast.error("Uh Oh an error occured please try again");
    }
  }


  const handleImages = ()=>{}
  return (
    <div className="flex justify-start items-center isolate flex-col w-full relative  -mt-8 gap-6 lg:text-left text-center ">
         <div>
                <h1 className="font-bold text-[30px] md:text-[38px] text-darkOrange tracking-[0.2em]">
                <span className="text-black font-medium">
                        Add Your <span className="text-[#E94E24] font-bold">Pet</span>
                    <span className="inline-block">
                    {/* <img src={blackO} className="w-[25px] h-[25px]" alt="" /> */}
                </span>{" "}
                </span>{" "}
                
            </h1>
            <p className="text-[#b1b1b1] text-xl font-medium tracking-wider">
                Please enter your pet basic info
            </p>
            </div>
      <div className="relative gap-4 flex-col lg:flex-row flex justify-between items-start w-full lg:mt-[80px] xl:w-[90%] mx-auto">

        
        {/* <div
          className={`xl:w-[35%] w-full relative ${
            serviceProp.img
              ? "flex justify-start max-w-[800px]  items-center xl:items-start gap-2 relative flex-col lg:pl-12"
              : ""
          }   ${serviceProp.img ? "h-[612px]" : ""}`}
        >
          {serviceProp.img ? (
            <>
              <h1 className=" font-semibold text-[32px]">{serviceProp.name}</h1>
              <p className=" text-[22px] font-light max-w-[388px] leading-[50px]">
                {serviceProp.desc}
              </p>{" "}
              <button className=" h-[62px] sm:w-auto w-full flex justify-center items-center rounded-[12px] bg-mustard shadow-shadow-btn text-white py-[18px] px-[41px] font-bold text-[15px]">
                Book Now{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="ml-3 text-[18px]"
                />
              </button>
              <img
                src={serviceProp.img}
                className="absolute max-w-[90%] left-0 -bottom-[60px] -z-[1] -ml-10"
                alt=""
              />
            </>
          ) : (
            <img
              className="hidden lg:block w-full xl:w-[70%] object-fill top-0 -left-[10%] absolute "
              src={Parrot}
              alt=""
            />
          )}
        </div> */}
        {/* cursor-pointer relative flex justify-start flex-col items-center flex-wrap lg:grid grid-cols-[auto_auto_auto_auto] grid-rows-2 w-full xl:w-[70%] gap-5 mb-[50px] lg:mb-0 */}
        <div className="w-full lg:w-[50%]">
           

            <div className="formContainer  my-10">
                <div className="eachFeild text-left">
                    <div className="text-lg font-semibold my-3">
                        Pet Name
                    </div>
                    <input onChange={(e)=>setName(e.target.value)} value={name} type="text" placeholder="e.g Tommy" className="text-xl p-2 border-2 w-full font-light text-[#E0E0E0] xl:w-[80%] outline-none border-[#E0E0E0] rounded-xl" />
                </div>
            </div>

            <div className="formContainer  my-10">
                <div className="eachFeild text-left">
                    <div className="text-lg font-semibold my-3">
                        Age
                    </div>
                    <input onChange={(e)=>setAge(e.target.value)} type="text" value={age} placeholder="e.g 1 year 1 month" className="text-xl p-2 border-2 w-full font-light text-[#E0E0E0] xl:w-[80%] outline-none border-[#E0E0E0] rounded-xl" />
                </div>
            </div>

            <div className="formContainer  my-10">
                <div className="eachFeild text-left relative">
                    <div className="text-lg font-semibold my-3">
                        Breed
                    </div>
                    <div onClick={()=>setBreedDD(!breedDD)} className="border-2 w-full bg-white font-light text-[#E0E0E0] xl:w-[80%] outline-none border-[#E0E0E0] rounded-xl flex items-center cursor-pointer">
                        <input type="text" value={breed} placeholder="e.g Select pets gender" className="text-xl p-2 w-full font-light text-[#E0E0E0] outline-none cursor-pointer  rounded-xl" />
                        <AiFillCaretDown className="text-black mr-3 text-xl" />
                    </div>
                    {breedDD && <div className="absolute top-[100%] w-full bg-white xl:w-[80%] z-10 letf-0 shadow-lg ">
                       {breeds.map(eachBreed=>(
                        <div>
                            <div onClick={()=>handleBreed(eachBreed.value)} className="eachDropOpt w-full  bg-white cursor-pointer p-2 text-xl font-thin">
                                {eachBreed.type}
                            </div>
                            <hr />
                        </div>
                       ))}                          
                    </div>}
                </div>
            </div>



        </div>
        <div className="w-full lg:w-[50%]">
           

            <div className="formContainer  my-10">
                <div className="eachFeild text-left">
                    <div className="text-lg font-semibold my-3">
                       Pet Weight
                    </div>
                    <input onChange={(e)=>setWeight(e.target.value)} value={weight} type="text" placeholder="e.g 5kg" className="text-xl p-2 border-2 w-full font-light text-[#E0E0E0] xl:w-[80%] outline-none border-[#E0E0E0] rounded-xl" />
                </div>
            </div>

            <div className="formContainer  my-10">
                <div className="eachFeild text-left relative">
                    <div className="text-lg font-semibold my-3">
                        Gender
                    </div>
                    <div onClick={()=>setGenderDD(!genderDD)} className="border-2 w-full font-light bg-white text-[#E0E0E0] xl:w-[80%] outline-none border-[#E0E0E0] rounded-xl flex items-center cursor-pointer">
                        <input type="text" value={gender=="male"?"Male":"Female"} placeholder="e.g Select pets gender" className="text-xl p-2 w-full font-light text-[#E0E0E0] outline-none cursor-pointer  rounded-xl" />
                        <AiFillCaretDown className="text-black mr-3 text-xl" />
                    </div>
                    {genderDD && <div className="absolute top-[100%] w-full bg-white xl:w-[80%] z-10 letf-0 shadow-lg ">
                        <div onClick={()=>handleGender("male")} className="eachDropOpt w-full  bg-white cursor-pointer p-2 text-xl font-thin">
                            Male
                        </div>  
                        <hr />
                        <div onClick={()=>handleGender("female")} className="eachDropOpt w-full  bg-white cursor-pointer p-2 text-xl font-thin">
                            Female
                        </div>
                    </div>}
                </div>
            </div>

            <div className="formContainer  my-10">
                <div className="eachFeild text-left relative">
                    <div className="text-lg font-semibold my-3">
                        Upload Photos
                    </div>
                    <div className="border-2 p-5 gap-2 w-full font-light bg-white text-[#E0E0E0] xl:w-[80%] outline-none border-[#E0E0E0] rounded-xl flex flex-wrap items-center cursor-pointer">
                        {images.map(each=>(
                      <div className="border-2 border-dashed border-[#E0E0E0] rounded-lg p-1">
                         <img className="w-[100px] object-cover h-[100px] rounded-xl" src={each} alt="" />
                      </div>  
                        ))}
                      {/* <div className="border-2 border-dashed border-[#E0E0E0] rounded-lg p-1">
                        <img className="w-[100px] object-cover h-[100px] rounded-xl" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzqAJ9Q25xQF_idUtlE89VcftHts-b6MblY56TccEWrFxm1l9GXzRqH8qy9WkiKgayAPU&usqp=CAU" alt="" />
                      </div> */}
                      <div class="image-upload" className="">
                        <label for="file-input">
                            <div className="border-2 border-dashed border-[#E0E0E0] rounded-lg p-1 cursor-pointer">
                                <div className="imgBtn w-[100px] h-[100px] flex justify-center items-center rounded-xl bg-red-100">
                                    <AiOutlinePlus className="text-5xl text-[#E94E24]" />
                                </div>
                            </div>
                        </label>

                        <input onChange={encodeImageFileAsURL} style={{height:"0",width:"0",display:"none"}} id="file-input" type="file" />
                        </div>
                      
                    </div>
                </div>
            </div>
            


        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-1 justify-center items-center lg:gap-3">
        <div className="registerBtn lg:my-10 w-[200px]">
                <div className="text-lg font-semibold my-3 w-full text-[#E94E24] border-2 border-[#E94E24]  p-4 text-xl rounded-xl text-center cursor-pointer">
                    Skip
                </div>
        </div>
                <div onClick={handleSubmit} className="registerBtn lg:my-10 w-[200px]">
                    <div className="text-lg font-semibold my-3 w-full  bg-[#E94E24] text-[#ffffff] p-4 text-xl rounded-xl text-center cursor-pointer ">
                        Add Now
                    </div>
                </div>
      </div>
    </div>
  );
}

export default Services;
