import React, { useState } from "react";
import blackO from "../Assets/black-o.svg";
import Card from "../Components/Card";
import card1 from "../Assets/card1.png";
import card2 from "../Assets/card2.png";
import card3 from "../Assets/card3.png";
import card4 from "../Assets/card4.png";
import card5 from "../Assets/card5.png";
import card6 from "../Assets/card6.png";
import cat from "../Assets/cat.png";
import service1 from "../Assets/service1.png";
import service2 from "../Assets/service2.png";
import service3 from "../Assets/service3.png";
import service4 from "../Assets/service4.png";
import service5 from "../Assets/service5.png";
import service6 from "../Assets/service6.png";
import Logo from "../Assets/Logo.png";

import Parrot from "../Assets/parrotRegister.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineCheck } from "react-icons/ai";

function Services() {
  const [check, setCheck] = useState(false);
  const [serviceProp, setServiceProp] = useState({
    name: "",
    desc: "",
    img: "",
  });
  return (
    <div className="flex justify-start items-center isolate flex-col w-full relative  -mt-8 gap-6 lg:text-left text-center ">
      <div className="relative xl:flex-row flex-col-reverse flex justify-between items-start w-full lg:mt-[80px]">
        <div
          className={`xl:w-[35%] w-full relative ${
            serviceProp.img
              ? "flex justify-start max-w-[800px]  items-center xl:items-start gap-2 relative flex-col lg:pl-12"
              : ""
          }   ${serviceProp.img ? "h-[612px]" : ""}`}
        >
          {serviceProp.img ? (
            <>
              <h1 className=" font-semibold text-[32px]">{serviceProp.name}</h1>
              <p className=" text-[22px] font-light max-w-[388px] leading-[50px]">
                {serviceProp.desc}
              </p>{" "}
              <button className=" h-[62px] sm:w-auto w-full flex justify-center items-center rounded-[12px] bg-mustard shadow-shadow-btn text-white py-[18px] px-[41px] font-bold text-[15px]">
                Book Now{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="ml-3 text-[18px]"
                />
              </button>
              <img
                src={serviceProp.img}
                className="absolute max-w-[90%] left-0 -bottom-[60px] -z-[1] -ml-10"
                alt=""
              />
            </>
          ) : (
            <img
              className="hidden lg:block w-full xl:w-[70%] object-fill top-0 -left-[10%] absolute "
              src={Parrot}
              alt=""
            />
          )}
        </div>
        {/* cursor-pointer relative flex justify-start flex-col items-center flex-wrap lg:grid grid-cols-[auto_auto_auto_auto] grid-rows-2 w-full xl:w-[70%] gap-5 mb-[50px] lg:mb-0 */}
        <div className="xl:w-[65%]">
          <div>
            <h1 className="font-bold text-[30px] md:text-[38px] text-darkOrange tracking-[0.2em]">
              <span className="text-black font-medium">
                Register With <span className="text-[#E94E24]">pett</span>
                <span className="inline-block">
                  <img src={blackO} className="w-[25px] h-[25px]" alt="" />
                </span>{" "}
              </span>{" "}
            </h1>
            <p className="text-[#b1b1b1] text-xl font-medium tracking-wider">
              Please signup first to add your pet.
            </p>
          </div>

          <div className="formContainer  my-10">
            <div className="eachFeild text-left">
              <div className="text-lg font-semibold my-3">Full Name</div>
              <input
                type="text"
                placeholder="e.g Jhon Doe"
                className="text-lg p-4 border-2 w-full font-light text-[#E0E0E0] xl:w-[50%] outline-none border-[#E0E0E0] rounded-xl"
              />
            </div>
          </div>

          <div className="formContainer  my-10">
            <div className="eachFeild text-left">
              <div className="text-lg font-semibold my-3">Email</div>
              <input
                type="text"
                placeholder="e.g jhon@gmail.com"
                className="text-lg p-4 border-2 w-full font-light text-[#E0E0E0] xl:w-[50%] outline-none border-[#E0E0E0] rounded-xl"
              />
            </div>
          </div>

          <div className="formContainer  my-10">
            <div className="eachFeild text-left">
              <div className="text-lg font-semibold my-3">Password</div>
              <input
                type="text"
                placeholder="e.g @#123abCD"
                className="text-lg p-4 border-2 w-full font-light text-[#E0E0E0] xl:w-[50%] outline-none border-[#E0E0E0] rounded-xl"
              />
            </div>
          </div>

          <div className="formContainer  my-10">
            <div className="eachFeild text-left">
              <div className="text-lg font-semibold my-3">Confirm Password</div>
              <input
                type="text"
                placeholder="e.g @#123abCD"
                className="text-lg p-4 border-2 w-full font-light text-[#E0E0E0] xl:w-[50%] outline-none border-[#E0E0E0] rounded-xl"
              />
            </div>
          </div>

          <div
            onClick={() => setCheck(!check)}
            className="my-10 flex items-center cursor-pointer"
          >
            <div
              className={`${
                check
                  ? "bg-[#E94E24] flex justify-center items-center"
                  : "bg-white border-[#E0E0E0] border-2"
              } w-[20px] h-[20px] `}
            >
              {check && <AiOutlineCheck className="text-white" />}
            </div>
            <div className=" text-thin text-[#E0E0E0] ml-1 md:ml-2">
              I agree to the{" "}
              <span className="text-[#E94E24]">terms & Condition</span> &{" "}
              <span className="text-[#E94E24]">Privacy Policy</span>
            </div>
          </div>

          <div className="registerBtn my-10">
            <div className="text-lg font-semibold my-3 w-full xl:w-[50%] bg-[#FFBA0C] text-[#ffffff] p-4 text-xl rounded-xl text-center cursor-pointer">
              Register
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
