import React from "react";
import TrustSaftey from "../../Assets/TrustSaftey.svg";

function TrustSafety() {
  const divStyle = {
    backgroundImage: `url(${TrustSaftey})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "80%",
    width: "100%",
    height: "90vh", // Set a fixed height for the div
  };

  return (
    <div className="bg-[#F7F7F7]">
      <div style={divStyle}></div>
    </div>
  );
}

export default TrustSafety;
