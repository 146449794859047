import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
function Footer() {
  return (
    <div className="w-full px-2 md:px-10 bg-black flex justify-center gap-[160px] items-center py-12 flex-col">
      <div style={{alignItems:'start'}} className="w-full items-start sm:items-start  bg-black flex flex-col 2xl:flex-row lg:grid grid-cols-1 lg:grid-cols-3 2xl:flex justify-center md:items-center  lg:justify-start gap-10 lg:gap-[160px] 2xl:items-start ">
        <div className="flex items-start 2xl:flex-row lg:grid grid-cols-1 lg:grid-cols-3 2xl:flex justify-center items-start lg:justify-start gap-10 lg:gap-[180px] 2xl:items-start ">
          <div className="w-full md:w-[200px]  flex justify-start items-center lg:items-start flex-col gap-[15px]">
            <h2 className="text-white font-bold text-xl">Learn More</h2>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Read Our Blog
            </a>
            <a
              href="#"
              className="text-white no-underline text-center md:text-left text-[15px] font-normal"
            >
              petto Q&A Community
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              petto Store
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              petto Guarantee
            </a>
          </div>
          <div className="w-full md:w-[200px] flex justify-start items-center lg:items-start flex-col gap-[15px]">
            <h2 className="text-white font-bold text-xl">About Petto</h2>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              About Us
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Contact Us
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Accessibility
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Get the App
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Press
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Careers
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Investor Relations
            </a>

            <span className="block md:hidden flex justify-start items-center lg:items-start flex-col gap-[15px]">
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Privacy Statement
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Cookie Policy
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Accessibility
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal text-center md:text-left"
            >
              CA - Do Not Sell My Info
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Terms of Service
            </a>
            </span>
          </div>
        </div>
        {/*  */}
        <div className="flex hidden  md:block items-start 2xl:flex-row lg:grid grid-cols-1 lg:grid-cols-3 2xl:flex justify-center items-start lg:justify-start gap-10 lg:gap-[180px] 2xl:items-start ">
        <div className="flex justify-start w-full md:w-[200px]  items-center w-full lg:items-start flex-col gap-[15px]">
        <h2 className="text-white font-bold text-xl invisible">About Petto</h2>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Privacy Statement
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Cookie Policy
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Accessibility
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal text-center md:text-left"
            >
              CA - Do Not Sell My Info
            </a>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Terms of Service
            </a>
            </div>
        </div>
        {/*  */}
        {/* <div className="flex  items 2xl:flex-row lg:grid grid-cols-1 lg:grid-cols-3 2xl:flex justify-center items-start  lg:justify-start gap-10 lg:gap-[160px] 2xl:items-start "> */}
        <div className="flex w-[100%] items 2xl:flex-row lg:grid grid-cols-1 lg:grid-cols-3 2xl:flex justify-center md:items-start  lg:justify-start gap-10 lg:gap-[160px] 2xl:items-start ">
          <div className=" flex justify-start items-center lg:items-start flex-col gap-[15px]">
            <h2 className="text-white font-bold text-xl">Need Help?</h2>
            <a
                href="#"
                className="text-white no-underline text-[15px] font-normal"
              >
                Help Center
              </a>
          </div>
          <div className="flex justify-start items-center lg:items-start flex-col gap-[15px]">
         
          <h2 className="text-white font-bold text-xl">Connect</h2>
            <div className="flex justify-center items-center gap-4">
              <a
                href="#"
                className="w-[30px] flex justify-center items-center h-[30px] rounded-full bg-lightWhite text-white no-underline text-lg"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="#"
                className="w-[30px] flex justify-center items-center h-[30px] rounded-full bg-lightWhite text-white no-underline text-lg"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="#"
                className="w-[30px] flex justify-center items-center h-[30px] rounded-full  text-white bg-lightWhite no-underline text-lg"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          {/* </div> */}
          
        </div>
          
          {/* <div className="flex justify-start items-center lg:items-start flex-col gap-[15px]">
            <h2 className="text-white font-bold text-xl">Need Help?</h2>
            <a
              href="#"
              className="text-white no-underline text-[15px] font-normal"
            >
              Help Center
            </a>
          </div> */}
        </div>
        {/* <div> */}
        {/* <div className="sm:flex w-[full] mx-auto items 2xl:flex-row lg:grid grid-cols-1 lg:grid-cols-3 2xl:flex justify-center items-start  lg:justify-start gap-10 lg:gap-[160px] 2xl:items-start block ">
          <div className="flex justify-start items-center lg:items-start flex-col gap-[15px] block md:hidden">
              <h2 className="text-white font-bold text-xl">Need Help?</h2>
              <a
                href="#"
                className="text-white no-underline text-[15px] font-normal"
              >
                Help Center
              </a>
            </div>
          <div className="flex justify-start items-center lg:items-start flex-col gap-[15px]">
            <h2 className="text-white font-bold text-xl bg-red-200">Connect</h2>
            <div className="flex justify-center items-center gap-4">
              <a
                href="#"
                className="w-[30px] flex justify-center items-center h-[30px] rounded-full bg-lightWhite text-white no-underline text-lg"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="#"
                className="w-[30px] flex justify-center items-center h-[30px] rounded-full bg-lightWhite text-white no-underline text-lg"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="#"
                className="w-[30px] flex justify-center items-center h-[30px] rounded-full  text-white bg-lightWhite no-underline text-lg"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
          </div> */}
        {/* </div> */}
      </div>
      <p className="text-white text-base text-center">
        © 2022 A Place for Petto, Inc. All Rights Reserved.
      </p>
    </div>
  );
}

export default Footer;
