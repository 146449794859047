import React, { useState } from "react";
import { toast } from "react-toastify";
import parrot from "../Assets/parrot.png";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import {BsMap} from 'react-icons/bs'
import { AiOutlineCalendar } from "react-icons/ai";
import url from '../url/url'

function BecomeSitter() {
  const [form, setForm] = useState({
    name: "",
    zip: "",
    email: "",
    password: "",
    confirm: "",
    address:"",
    DOB:"",
    phoneNumber:""
  });
  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      form.name === "" ||
      form.email === "" ||
      form.address === "" ||
      form.phoneNumber ===""||
      form.DOB === "" ||
      form.password === "" ||
      form.password === ""
    ) {
      return toast.error("Please fill the form!");
    }
    if (form.password === form.confirm) {
      const body = {
        FullName:form.name,
        Email:form.email,
        Password:form.password,
        ConfirmPassword:form.password,
        CountryCode:"+971",
        PhoneNumber:form.phoneNumber,
        Address:form.address,
        DOB:form.DOB
      }
      try {
        const addResp = await url.post(`/sitter/add`,body)
        console.log(addResp.data.data)
        setForm({ name: "",
        zip: "",
        email: "",
        password: "",
        confirm: "",
        address:"",
        DOB:"",
        phoneNumber:""});
        return toast.success("registered successfully");
      } catch (error) {
        return toast.error("Uh Oh this email is already registered please try again with another email");
      }
      const userCollection = collection(db, "Users");
      addDoc(userCollection, {
        name: form.name,
        email: form.email,
        password: form.password,
        zip: form.zip,
      })
        .then(() => {
          return toast.success("Pet registered successfully");
        })
        .catch((err) => {
          // console.log(err);
          return toast.error("Uh Oh an error occurred please try again");
        })
        .finally(() => {
          setForm({ name: "",
          zip: "",
          email: "",
          password: "",
          confirm: "",
          address:"",
          DOB:"",
          phoneNumber:""});
        });
    } else {
      toast.error("Passwords dont match!");
    }

    console.log('data: ',form)
  };
  return (
    <div className="flex justify-start items-center isolate flex-col w-full relative gap-6  sm:text-left text-center">
      <div className="flex justify-between items-start w-full gap-9">
        <img
          className="lg:block hidden w-[50%] -ml-[4rem] max-w-[373px] object-contain"
          src={parrot}
          alt=""
        />
        <div className="flex justify-start items-start w-full flex-col gap-6 lg:max-w-[60%] ">
          <h1 className="font-medium text-[38px] text-black">
            Become a <span className="text-darkOrange">Sitter</span>
          </h1>
          <p className="text-[#b1b1b1] text-xl font-medium tracking-wider">
            {/* Register your first pet with petto and relax */}
            Please sign up first to become a sitter.
          </p>
          <form
            onSubmit={submitHandler}
            className="w-full mt-6 gap-6 flex-col flex justify-center items-center xl:w-[70%]"
          >
            <div className="flex justify-center w-full items-start gap-4 flex-col">
              <label className="font-bold text-base" htmlFor="name">
                Full Name
              </label>
              <input
                value={form.name}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, name: e.target.value };
                  })
                }
                type="text"
                id="name"
                placeholder="Enter your full name"
                className="w-full pl-4 h-[60px] bg-white rounded-[12px] border-solid border-[1px] border-[#e0e0e0] text-[15px] text-[#b1b1b1]"
              />
            </div>
            <div className="flex justify-center w-full items-start gap-4 flex-col">
              <label className="font-bold text-base" htmlFor="email">
                Email Address
              </label>
              <input
                value={form.email}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, email: e.target.value };
                  })
                }
                type="text"
                id="email"
                placeholder="Enter your email address"
                className="w-full pl-4 h-[60px] bg-white rounded-[12px] border-solid border-[1px] border-[#e0e0e0] text-[15px] text-[#b1b1b1]"
              />
            </div>
            {/* <div className="flex justify-center w-full items-start gap-4 flex-col">
              <label className="font-bold text-base" htmlFor="zip">
                Zip/Postal Code
              </label>
              <input
                value={form.zip}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, zip: e.target.value };
                  })
                }
                type="text"
                id="zip"
                placeholder="Enter your Zip/Postal Code"
                className="w-full pl-4 h-[60px] bg-white rounded-[12px] border-solid border-[1px] border-[#e0e0e0] text-[15px] text-[#b1b1b1]"
              />
            </div> */}
             <div className="flex justify-center w-full items-start gap-4 flex-col">
              <label className="font-bold text-base" htmlFor="number">
                Phone Number
              </label>
              <div className=" px-4 h-[60px] flex items-center w-full bg-white rounded-[12px] border-solid border-[1px] border-[#e0e0e0] text-[15px] text-[#b1b1b1]">
                <div className="c-code text-[#E94E24] font-semibold">
                  +971
                </div>
                <div className="h-[15px] w-[1px] bg-gray-400 mx-3"></div>
              <input
                value={form.phoneNumber}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, phoneNumber: e.target.value };
                  })
                }
                type="text"
                id="number"
                placeholder="e.g. 0 123 4567"
                className="w-full outline-none"
                />
                </div>
            </div>
            <div className="flex justify-center w-full items-start gap-4 flex-col">
              <label className="font-bold text-base" htmlFor="address">
                Address
              </label>
              <div className=" px-4 h-[60px] flex items-center w-full bg-white rounded-[12px] border-solid border-[1px] border-[#e0e0e0] text-[15px] text-[#b1b1b1]">
              <input
                value={form.address}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, address: e.target.value };
                  })
                }
                type="text"
                id="address"
                placeholder="e.g. PO Box xxxx Emirates or City U.A.E"
                className="w-full outline-none"
                />
                <BsMap className="text-[18px]" />
                </div>
            </div>
            <div className="flex justify-center w-full items-start gap-4 flex-col">
              <label className="font-bold text-base" htmlFor="address">
                Date of Birth
              </label>
              <div className=" px-4 h-[60px] flex items-center w-full bg-white rounded-[12px] border-solid border-[1px] border-[#e0e0e0] text-[15px] text-[#b1b1b1]">
              <input
                value={form.DOB}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, DOB: e.target.value };
                  })
                }
                type="text"
                id="address"
                placeholder="e.g. 20-01-1994"
                className="w-full outline-none"
                />
                <AiOutlineCalendar className="text-[20px]" />
                </div>
            </div>
            <div className="flex justify-center w-full items-start gap-4 flex-col">
              <label className="font-bold text-base" htmlFor="pwd">
                Password
              </label>
              <input
                value={form.password}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, password: e.target.value };
                  })
                }
                type="password"
                id="pwd"
                placeholder="Type the Password"
                className="w-full px-4 h-[60px] bg-white rounded-[12px] border-solid border-[1px] border-[#e0e0e0] text-[15px] text-[#b1b1b1]"
              />
            </div>
            <div className="flex justify-center w-full items-start gap-4 flex-col">
              <label className="font-bold text-base" htmlFor="cpwd">
                Confirm Password
              </label>
              <input
                value={form.confirm}
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, confirm: e.target.value };
                  })
                }
                type="password"
                id="cpwd"
                placeholder="Type the Password"
                className="w-full px-4 h-[60px] bg-white rounded-[12px] border-solid border-[1px] border-[#e0e0e0] text-[15px] text-[#b1b1b1]"
              />
            </div>
            <div className="flex items-center justify-start gap-2 w-full text-left">
              <input
                type="checkbox"
                className="accent-darkOrange w-[18px] h-[18px]"
              />
              <p className="text-base text-[#b1b1b1] ">
                I agree to the{" "}
                <span className="text-darkOrange font-semibold">
                  Terms & Conditions
                </span>{" "}
                &{" "}
                <span className="text-darkOrange font-semibold">
                  {" "}
                  Privacy Policy
                </span>
              </p>
            </div>
            <button className="rounded-[12px] w-full bg-mustard shadow-shadow-btn text-white py-[18px] px-[41px] font-bold text-[15px]">
              Register
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BecomeSitter;
