import React from "react";
import BecomeWalkerDummyImage from "../../Assets/BecomeWalkerDummyImage.svg";

function BecomeWalker() {
  const divStyle = {
    backgroundImage: `url(${BecomeWalkerDummyImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100%",
    width: "100%",
    height: "90vh", // Set a fixed height for the div
  };

  return (
    <div className="bg-[#F7F7F7]">
      <div style={divStyle}></div>;
    </div>
  );
}

export default BecomeWalker;
