import React from "react";
import WhyPettoDummyImage from "../../Assets/WhyPettoDummyImage.svg";

function WhyPetto() {
  const divStyle = {
    // backgroundImage: `url(${WhyPettoDummyImage})`,
    // backgroundSize: "contain",
    backgroundRepeat: "no-rep?eat",
    // backgroundPosition: "center",
    // backgroundSize: "80%",
    // width: "100%",
    // height: "90vh", // Set a fixed height for the div
  };

  return (
    <div className="w-100 pb-10  flex justify-center   ">
      <img src={WhyPettoDummyImage} className="" />
    </div>
  );
}

export default WhyPetto;
