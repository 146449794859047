import React from "react";
import VetPetTrainer from "../../Assets/VetPetTrainer.svg";

function VetDogTrainers() {
  const divStyle = {
    backgroundImage: `url(${VetPetTrainer})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "80%",
    width: "100%",
    height: "80vh", // Set a fixed height for the div
  };

  return (
    <div>
      <div style={divStyle}></div>;
    </div>
  );
}

export default VetDogTrainers;
