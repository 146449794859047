import React from "react";
import DummyTrainigImage from "../../Assets/BookTrainingSectionImage.svg";
function BookTraining() {
  const divStyle = {
    // backgroundImage: `url(${DummyTrainigImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    // backgroundSize: "100%",
    width: "100%",
    height: "55vh", // Set a fixed height for the div
  };

  return (
    <div
      style={divStyle}
      className="z-0 w-100 pb-5  flex justify-center   -mt-20"
    >
      <img src={DummyTrainigImage} className="z-50 rounded-lg drop-shadow	" />
    </div>
  );
}

export default BookTraining;
