import React from "react";
import SampleDummyReviews from "../../Assets/SampleDummyReviews.svg";

function SampleReviews() {
  const divStyle = {
    backgroundImage: `url(${SampleDummyReviews})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "80%",
    width: "100%",
    height: "90vh", // Set a fixed height for the div
  };

  return (
    <div>
      <div style={divStyle}></div>
    </div>
  );
}

export default SampleReviews;
