import React, { useEffect, useState } from 'react'
import './newHero.css'
import BannerImage from '../../Assets/slide_1.png'
import Video from '../../Assets/video.mp4'
import Video1 from '../../Assets/video1.mp4'
import Video2 from '../../Assets/Video2.mp4'
import Video3 from '../../Assets/video3.mp4'
import Video4 from '../../Assets/video4.mp4'
import Video5 from '../../Assets/video5.mp4'
import Video6 from '../../Assets/video6.mp4'
import Video7 from '../../Assets/video7.mp4'
import FinalVideo from '../../Assets/FinalVideo.mp4'

// import Video2 from '../../Assets/Video2.mp4'


const style = {
    mainContainer:{
        backgroundImage: `url(${require('../../Assets/slide_1.png')})`
    }
}
function NewHero() {
  let [videos,setVideos]=useState([Video,Video1,Video2,Video3,Video4,Video5,Video6,Video7])
  let [counter,setCounter] = useState(0)
  useEffect( () => {
    const interval = setInterval(() => {
      if(counter==7){
        console.log("hit")
        setCounter(0)
      }else{
        setCounter(counter+1)
      }
       
      // setVideos(1)
        // let video = document.getElementById('player');
        // video.src= videos[currentVideo];
        // video.load();
        // video.play();    
        // console.log(currentVideo)
    }, 5000);
    
    return () => clearInterval(interval); 
    }, [counter]);
  return (
    // video src => videos[counter]
    <div className="flex relative w-[100%] h-[90vh] justify-center items-center relative isolate">
       {/* <video  id="myVideo" src={FinalVideo} className='w-[100vw] h-[100%] object-cover' autoPlay muted loop /> */}
        {/* <img className='newHeroBgImage z-0 absolute h-full object-cover' src={BannerImage} alt="" /> */}
        <div className="absolute text-2xl font-bold z-10">
          <h1 className="text-center tracking-[0.2em] text-white text-2xl md:text-3xl font-medium">
            Trust the Best with Your Pets
          </h1>
        </div>
    </div>
  )
}

export default NewHero