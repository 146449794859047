import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import pet1 from "../Assets/pet2Big 2.svg";
import pet2 from "../Assets/rabbit1New.svg";
import pet3 from "../Assets/parrot1New.svg";
import humen1 from "../Assets/svg_img_1.svg";
import humen2 from "../Assets/svg_img_2.svg";
import heroBg from "../Assets/hero-bg.png";
import logo from "../Assets/Logo.png";
import BGImage from "../Assets/slide_1.png";
import { AnimatePresence, motion } from "framer-motion";
import Video from "../Assets/video.mp4";
import Video1 from "../Assets/video1.mp4";
import Video2 from "../Assets/Video2.mp4";
import Video3 from "../Assets/video3.mp4";
import Video4 from "../Assets/video4.mp4";
import Video5 from "../Assets/video5.mp4";
import Video6 from "../Assets/video6.mp4";
import Video7 from "../Assets/video7.mp4";
import FinalVideo from "../Assets/VideoFinalI.mp4";

function Hero({ setModalName, setModal }) {
  let [videos, setVideos] = useState([
    Video,
    Video1,
    Video2,
    Video3,
    Video4,
    Video5,
    Video6,
    Video7,
  ]);
  let [counter, setCounter] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (counter == 7) {
        console.log("hit");
        setCounter(0);
      } else {
        setCounter(counter + 1);
      }

      // setVideos(1)
      // let video = document.getElementById('player');
      // video.src= videos[currentVideo];
      // video.load();
      // video.play();
      // console.log(currentVideo)
    }, 5000);

    return () => clearInterval(interval);
  }, [counter]);
  const [bg, setBg] = useState("first");
  useEffect(() => {
    setTimeout(() => {
      switch (bg) {
        case "first":
          setBg("second");
          break;
        case "second":
          setBg("third");
          break;
        case "third":
          setBg("forth");
          break;
        case "forth":
          setBg("fifth");
          break;
        case "fifth":
          setBg("first");
          break;
        default:
          setBg("first");
          break;
      }
    }, 4000);
  }, [bg]);

  // style={{backgroundImage: `url(${require('../Assets/slide_1.png')})`}}

  return (
    // px-5
    <div className="heroMainClass flex relative w-full h-[100%] justify-center items-center relative isolate overflow-hidden">
      <video
        style={{ height: "calc(100vh - 90px)" }}
        id="myVideo"
        src={FinalVideo}
        className="heroContainer w-[100vw] h-[calc()] object-cover"
        autoPlay
        muted
      />
      {/* max-w-[865px] */}
      <div className="heroContainer w-full w-[100%] md:w-[1000px] lg:w-[1200px] xl:w-[1400px] absolute flex items-center justify-center  min-h-[900px] flex-col gap-9 ">
        {/* animation images.. */}
        <img
          className="mx-auto w-[70%] object-fit md:w-auto"
          src={logo}
          alt=""
        />
        <h1 className="text-center tracking-[0.2em] text-white text-2xl md:text-3xl font-medium">
          Trust the Best with Your Pets
        </h1>
        <div className="sm:flex-row flex-col flex justify-center items-center gap-5 w-full sm:w-auto">
          <button
            onClick={() => {
              setModal(true);
              setModalName("pet");
            }}
            className="h-[62px] sm:w-auto w-full flex justify-center items-center rounded-[12px] bg-mustard shadow-shadow-btn text-white py-[18px] px-[41px] font-bold text-[15px]"
          >
            Register Your Pet{" "}
            <FontAwesomeIcon icon={faArrowRight} className="ml-3 text-[18px]" />
          </button>
          <button
            onClick={() => {
              setModal(true);
              setModalName("become-sitter");
            }}
            className="h-[62px] sm:w-auto w-full rounded-[12px] bg-transparent border-2 border-white border-solid text-white py-[18px] px-[41px] font-bold text-[15px]"
          >
            Become a Sitter
          </button>
        </div>
      </div>
    </div>
  );
}

// <div className="relative isolate w-full mt-[1rem] md:mt-[12rem] lg:mt-[14rem]">
// <AnimatePresence wait>
// {/* image class: sm:w-auto sm:h-auto w-[414px] h-[320px] lg:w-[1114px] lg:h-[620px] xl:w-[1414px] xl:h-[720px]  max-w-full absolute -z-10 -bottom-10 left-1/2 -translate-x-1/2 */}
// {bg === "first" && (
//   <motion.img
//     initial={{ opacity: 0 }}
//     animate={{ opacity: 1 }}
//     exit={{ opacity: 0 }}
//     key={bg}
//     src={pet1}
//     className="sm:w-auto sm:h-auto w-[414px] h-[320px]  md:w-[850px] md:h-[550px]  lg:w-[1014px] lg:h-[620px]  xl:w-[1150px] xl:h-[600px]  2xl:w-[1250px] 2xl:h-[670px] 3xl:w-[1414px] 3xl:h-[720px]  max-w-full absolute -z-10 -bottom-10 left-1/2 -translate-x-1/2"
//     alt=""
//   />
// )}
// {/* bg === "second" */}
// { bg === "second" && (
//   <motion.img
//     key={bg}
//     initial={{ opacity: 0 }}
//     animate={{ opacity: 1 }}
//     exit={{ opacity: 0 }}
//     src={pet2}
//     className="sm:w-auto sm:h-auto w-[414px] ml-[70px] lg:ml-[170px] h-[320px]  md:w-[850px] md:h-[550px]  lg:w-[1014px] lg:h-[620px]  xl:w-[1150px] xl:h-[600px]  2xl:w-[1250px] 2xl:h-[670px] 3xl:w-[1414px] 3xl:h-[720px]  max-w-full absolute -z-10 -bottom-10 left-1/2 -translate-x-1/2"
//     alt=""
//   />
// )}
// {/* ml-[70px] lg:ml-[170px] */}
// {bg === "third" && (
//   <motion.img
//     key={bg}
//     initial={{ opacity: 0 }}
//     animate={{ opacity: 1 }}
//     exit={{ opacity: 0 }}
//     src={pet3}
//     className="sm:w-auto sm:h-auto w-[414px] h-[320px]  md:w-[850px] md:h-[550px]  lg:w-[1014px] lg:h-[620px]  xl:w-[1150px] xl:h-[600px]  2xl:w-[1250px] 2xl:h-[670px] 3xl:w-[1414px] 3xl:h-[720px]  max-w-full absolute -z-10 -bottom-10 left-1/2 -translate-x-1/2"
//     alt=""
//   />
// )}
// {bg === "forth" && (
//   <motion.img
//     key={bg}
//     initial={{ opacity: 0 }}
//     animate={{ opacity: 1 }}
//     exit={{ opacity: 0 }}
//     src={humen1}
//     className="sm:w-auto sm:h-auto w-[414px] h-[320px]  md:w-[850px] md:h-[550px]  lg:w-[1014px] lg:h-[620px]  xl:w-[1150px] xl:h-[600px]  2xl:w-[1250px] 2xl:h-[670px] 3xl:w-[1414px] 3xl:h-[720px]  max-w-full absolute -z-10 -bottom-10 left-1/2 -translate-x-1/2"
//     alt=""
//   />
// )}
// {/* sm:w-auto sm:h-auto w-[414px] h-[320px] lg:w-[1114px] lg:h-[620px] xl:w-[1414px] xl:h-[720px]  max-w-full absolute -z-10 -bottom-10 left-1/2 -translate-x-1/2 */}
// {/* bg === "fifth" */}
// { bg === "fifth" && (
//   <motion.img
//     key={bg}
//     initial={{ opacity: 0 }}
//     animate={{ opacity: 1 }}
//     exit={{ opacity: 0 }}
//     src={humen2}
//     className="sm:w-auto sm:h-auto w-[414px] h-[320px]  md:w-[850px] md:h-[550px]  lg:w-[1014px] lg:h-[620px]  xl:w-[1150px] xl:h-[600px]  2xl:w-[1250px] 2xl:h-[670px] 3xl:w-[1414px] 3xl:h-[720px]  max-w-full absolute -z-10 -bottom-10 left-1/2 -translate-x-1/2"
//     alt=""
//   />
// )}
// </AnimatePresence>
// </div>

export default Hero;
